<article class="privacy">
  <section class="privacy_content">
    <!-- /.privacy privacy_content -->
    <!-- /.privacy -->
    <h1 class="text-center">
      <!-- /.text-center -->Pixie Gardens<br />
      Terms and Conditions
    </h1>

    <p class="privacy-info">
      <!-- /.privacy-info -->Effective January 15, 2021
    </p>
    <p>
      Please read these terms and conditions of use before visiting
      pixiegardens.ca. Pixie Gardens, herein is referred to as the “Website”.
      The Terms and Conditions constitute a binding agreement that governs the
      use of the Website, limits the liability of Pixie Gardens and others and
      contains other important provisions.
    </p>
    <p>
      By visiting our site, you engage in our “Service” and agree to be bound by
      the following terms and conditions, including those additional terms and
      conditions and policies referenced herein and/or available by hyperlink.
    </p>
    <p>
      By accessing or using any part of the site, you agree to be bound by these
      Terms. If you do not agree to all the terms and conditions of this
      agreement, then you may not access the website or use any services. If
      these Terms are considered an offer, acceptance is expressly limited to
      these Terms.
    </p>
    <p>
      Any new features or tools which are added to the current website shall
      also be subject to the Terms. You can review the most current version of
      the Terms at any time on this page. We reserve the right to update, change
      or replace any part of these Terms by posting updates and/or changes to
      our website. It is your responsibility to check this page periodically for
      changes. Your continued use of or access to the website following the
      posting of any changes constitutes acceptance of those changes.
    </p>
    <H2>GENERAL CONDITIONS</H2>
    <p>
      The content contained within our Site, belongs to Pixie Gardens. We retain
      the right to the intellectual property, applicable trademarks and
      wordmarks, images, designs, copywriting content and other related
      material. The opinions expressed are ours. The content available on the
      Site is intended for your personal use only, not commercial benefit.
    </p>
    <p>
      Generally, we accept almost all publicly generated content on our Site in
      appropriate locations such as future blog commentary, but negative,
      libelous, defamatory, false or abusive user generated content will not be
      tolerated at any time. Inappropriate content will be removed or edited at
      our discretion. You understand that comments made to or into our Site,
      grant us the authority to use your comments, images or similar. You agree
      that you are responsible for comments submitted through our website.
    </p>
    <p>
      You understand that your content, such as your property address (not
      including credit card information), may be transferred unencrypted and
      involve (a) transmissions over various networks; and (b) changes to
      conform and adapt to technical requirements of connecting networks or
      devices. Credit card information is currently not accepted on the Site. We
      reserve the right to refuse service to anyone for any reason at any time.
    </p>
    <p>
      You agree not to reproduce, duplicate, copy, sell, resell or exploit any
      portion of the Site or the Service, use of the Service, or access to the
      Service or any contact on the website through which the Service is
      provided, without express written permission by us.
    </p>
    <h2>THIRD-PARTY LINKS</h2>
    <p>
      Certain content services available via our Service may include materials
      from third-parties.
    </p>
    <p>
      Third-party links on this site may direct you to third-party websites that
      are not affiliated with us. We are not responsible for examining or
      evaluating the content or accuracy and we do not warrant and will not have
      any liability or responsibility for any third-party materials or websites,
      or for any other materials, or services of third-parties. We are not
      liable for any harm or damages related to the purchase or use of goods,
      services, resources, content, or any other transactions made in connection
      with any third-party websites. Please review carefully the third-party’s
      policies and practices and make sure you understand them before you engage
      in any transaction. Complaints, claims, concerns, or questions regarding
      third-party products should be directed to the third-party.
    </p>
    <h2>ERRORS, INACCURACIES AND OMISSIONS</h2>
    <p>
      Occasionally there may be information on our site or in the Service that
      contains typographical errors, inaccuracies or omissions that may relate
      to service descriptions, pricing, promotions, offers, delivery times
      and/or availability. We reserve the right to correct any errors,
      inaccuracies or omissions, and to change or update information or cancel
      services if any information in the Service or on any related website is
      inaccurate at any time without prior notice.
    </p>
    <p>
      We undertake no obligation to update, amend or clarify information in the
      Service or on any related website, including without limitation, pricing
      information, except as required by law. No specified update or refresh
      date applied in the Service or on any related website, should be taken to
      indicate that all information in the Service or on any related website has
      been modified or updated.
    </p>
    <h2>LINKS TO THE WEBSITE & SOCIAL MEDIA</h2>
    <p>
      You may link to our home page or other Pixie Gardens webpage, provided you
      do so in a way that is legal and does not damage or take advantage of
      Pixie Gardens’ reputation. A link may not suggest any form of association,
      approval or endorsement on the part of Pixie Gardens unless given express
      consent by our Privacy Officer. We reserve the right to withdraw
      inappropriate links or those that do not fall under the above conditions
      at our discretion.
    </p>
    <p>
      Images and Site content are intended for non-commercial use. You may link
      to our site, post content to your personal social media and otherwise use
      the Site for personal use only. Pixie Gardens reserves the right to
      withdraw content that is being used inappropriately, illegally or in a
      defamatory manner.
    </p>
    <h2>TRADEMARKS, INTELLECTUAL PROPERTY RIGHTS, COPYRIGHTS AND MORE</h2>
    <p>
      Unless otherwise stated, the text, software, images, graphics, logos, icons,
      photographs, images, illustrations, audio clips, video clips, design
      elements, copyrights and copyrightable materials, trademarks, wordmarks,
      trade dress and other content are the exclusive property of Pixie Gardens,
      located in Calgary, Alberta, Canada. Except where such permission is
      specifically granted, you may not modify, publish, transmit, participate
      in the transfer or sale, create derivative works, or in any way exploit,
      any of the Website content, in whole or in part. You may download to your
      computer for viewing such copyrighted material for your personal use only.
      Except as otherwise expressly permitted under copyright law, no copying,
      redistribution, retransmission, publication or commercial exploitation of
      downloaded material will be permitted without the express permission of
      Pixie Gardens and, where applicable, the copyright owner. In the event of
      any permitted copying, redistribution or publication of copyrighted
      material, no changes in or deletion of author attribution, trademark
      legend or copyright notice shall be made. Pixie Gardens grants you no
      express or implied rights by way of your permitted downloading of
      copyrighted material.
    </p>
    <h2>USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h2>
    <p>
      If, at our request, you send certain specific submissions (for example,
      contest entries) or without a request from us, you send creative ideas,
      suggestions, proposals, plans, or other materials, whether online, by
      email, by postal mail, or otherwise (collectively, ‘comments’), you agree
      that we may, at any time, without restriction, edit, copy, publish,
      distribute, translate and otherwise use in any medium any comments that
      you forward to us. This includes comments made via other publicly
      accessible mediums such as Google Reviews and Facebook Reviews. We are and
      shall be under no obligation (1) to maintain any comments in confidence;
      (2) to pay compensation for any comments; or (3) to respond to any
      comments. Saying this, we will uphold a high degree of integrity and treat
      all comments with respect.
    </p>
    <p>
      We may, but have no obligation to, monitor, edit or remove content that we
      determine in our sole discretion are unlawful, offensive, threatening,
      libellous, defamatory, pornographic, obscene or otherwise objectionable or
      violates any party’s intellectual property or these Terms.
    </p>
    <p>
      You agree that your comments will not violate any right of any third
      party, including copyright, trademark, privacy, personality or other
      personal or proprietary right. You further agree that your comments will
      not contain libellous or otherwise unlawful, abusive or obscene material,
      or contain any computer virus or other malware that could in any way
      affect the operation of the Service or any related website. You may not
      use a false e-mail address, pretend to be someone other than yourself, or
      otherwise mislead us or third parties as to the origin of any comments.
      You are solely responsible for any comments you make and their accuracy.
      We take no responsibility and assume no liability for any comments posted
      by you or any third-party.
    </p>
    <h2>PERSONAL INFORMATION</h2>
    <p>
      Your submission of personal information through the Site is governed by
      our Privacy Policy. To view our Privacy Policy, refer to the appropriate
      material below. We reserve the opportunity to collect your personal
      information through offering you the opportunity to sign up to receive
      updates on future blog posts, email news and other similar marketing
      related information.
    </p>
    <h2>PROHIBITED USES</h2>
    <p>
      In addition to other prohibitions as set forth in the Terms, you are
      prohibited from using the site or its content: (a) for any unlawful
      purpose; (b) to solicit others to perform or participate in any unlawful
      acts; (c) to violate any international, federal, provincial or state
      regulations, rules, laws, or local ordinances; (d) to infringe upon or
      violate our intellectual property rights or the intellectual property
      rights of others; (e) to harass, abuse, insult, harm, defame, slander,
      disparage, intimidate, or discriminate based on gender, sexual
      orientation, religion, ethnicity, race, age, national origin, or
      disability; (f) to submit false or misleading information; (g) to upload
      or transmit viruses or any other type of malicious code that will or may
      be used in any way that will affect the functionality or operation of the
      Service or of any related website, other websites, or the Internet; (h) to
      collect or track the personal information of others; (i) to spam, phish,
      pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral
      purpose; or (k) to interfere with or circumvent the security features of
      the Service or any related website, other websites, or the Internet. We
      reserve the right to terminate your use of the Service or any related
      website under our authority for violating any of the prohibited uses.
    </p>
    <h2>DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h2>
    <p>
      We do not guarantee, represent or warrant that your use of our service
      will be uninterrupted, timely, secure or error-free. We do not warrant
      that the results that may be obtained from the use of the service will be
      accurate or reliable.
    </p>
    <p>
      In no case shall Pixie Gardens, our directors, officers, employees,
      affiliates, agents, contractors, interns, suppliers, service providers or
      licensors be liable for any injury, loss, claim, or any direct, indirect,
      incidental, punitive, special, or consequential damages of any kind,
      including, without limitation lost profits, lost revenue, lost savings,
      loss of data, replacement costs, or any similar damages, whether based in
      contract, tort (including negligence), strict liability or otherwise,
      arising from your use of any of the service or any products procured using
      the service, or for any other claim related in any way to your use of the
      service or any product, including, but not limited to, any errors or
      omissions in any content, or any loss or damage of any kind incurred as a
      result of the use of the service or any content (or product) posted,
      transmitted, or otherwise made available via the service, even if advised
      of their possibility.
    </p>
    <p>
      You agree to indemnify, defend and hold harmless Pixie Gardens and our
      parent, subsidiaries, affiliates, partners, officers, directors, agents,
      contractors, licensors, service providers, subcontractors, suppliers,
      interns and employees, harmless from any claim or demand, including
      reasonable attorneys’ fees, made by any third-party due to or arising out
      of your breach of these Terms or the documents they incorporate by
      reference, or your violation of any law or the rights of a third-party.
    </p>
    <p>
      Pixie Gardens retains the right to set, amend and update any warranties on
      any work or commitments made to clients, digitally or by traditional
      methods such as but not limited to in person or in printed communication.
    </p>
    <p>
      You agree that any custom products that are ordered by you, are your
      financial responsibility. It is understood that customized products
      typically cannot be returned, discounted or left unclaimed.
    </p>
    <h2>SEVERABILITY</h2>
    <p>
      In the event that any provision of these Terms of Service is determined to
      be unlawful, void or unenforceable, such provision shall nonetheless be
      enforceable to the fullest extent permitted by applicable law, and the
      unenforceable portion shall be deemed to be severed from these Terms, such
      determination shall not affect the validity and enforceability of any
      other remaining provisions.
    </p>
    <h2>ENTIRE AGREEMENT</h2>
    <p>
      The failure of us to exercise or enforce any right or provision of these
      Terms shall not constitute a waiver of such right or provision.
    </p>
    <p>
      These Terms and any policies or operating rules posted by us on this site
      or in respect to The Service constitutes the entire agreement and
      understanding between you and us and govern your use of the Service,
      superseding any prior or current agreements, communications and proposals,
      whether oral or written, between you and us (including, but not limited
      to, any prior versions of the Terms). Any ambiguities in the
      interpretation of these Terms of Service shall not be construed against
      the drafting party.
    </p>
    <H2>GOVERNING LAW</H2>
    <p>
      These Terms of Service and any separate agreements whereby we provide you
      Services shall be governed by and construed in accordance with the laws of
      Alberta, Canada.
    </p>
    CHANGES TO TERMS OF SERVICE
    <p>
      You can review the most current version of the Terms at any time on this
      page.
    </p>
    <p>
      We reserve the right, at our sole discretion, to update, change or replace
      any part of these Terms by posting updates and changes to our website. It
      is your responsibility to check our website periodically for changes. Your
      continued use of or access to our website or the Service following the
      posting of any changes to these Terms constitutes acceptance of those
      changes.
    </p>
    <H2>CONTACT INFORMATION</H2>

    <p>Questions about the Terms and Conditions should be sent to us at:</p>
    <p class="privacy_address">
      Pixie Gardens, <br />
      Attention: Privacy Officer <br />Box 15061 Aspen Woods RPO <br />Calgary
      AB T3H 0N8 <br />Ph: (403)228-2802 <br />Email: office@pixiegardens.ca
    </p>
  </section>
</article>
