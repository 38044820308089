import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meetus',
  templateUrl: './meetus.component.html',
  styleUrls: ['./meetus.component.css']
})
export class MeetusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
