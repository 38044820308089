<footer>
  <!--  <div class="upper">-->
  <!--    <div class="services">-->
  <!--      <h5>Calgary Gardening Services</h5>-->
  <!--      <ul>-->
  <!--        <li>Garden Maintenance</li>-->
  <!--        <li>Seasonal Containers</li>-->
  <!--        <li>Rain Wise Gardens</li>-->
  <!--      </ul>-->
  <!--    </div>-->
  <!--    <div class="member-of">-->
  <!--      <h5>Proud Member of</h5>-->
  <!--      <img src="../../../assets/icons/landscape-ab-logo-v2.jpg" alt="" />-->
  <!--    </div>-->
  <!--    <div class="contact-us">-->
  <!--      <h5>Contact Information</h5>-->
  <!--      <p>Call us: <a href="tel:4032282802">403.228.2802</a></p>-->
  <!--      <p>-->
  <!--        Email us:-->
  <!--        <a href="mailto:office@pixiegardens.ca">office@pixiegardens.ca</a>-->
  <!--      </p>-->
  <!--      <p>-->
  <!--        Box 15061 Aspen Woods RPO<br />-->
  <!--        Calgary, AB T3H 0N8-->
  <!--      </p>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="lower">
    <div class="copy">
      &copy; {{ copyYear | date: "yyyy" }} |
      <i class="fa fa-heart heart"></i> Pixie Gardens Inc.
    </div>
    <div class="links">
      <ul>
        <li>
          <p><a routerLink="privacy-policy">Privacy Policy</a></p>
        </li>
        <li><p>|</p></li>
        <li>
          <p><a routerLink="terms-and-conditions">Terms & Conditions</a></p>
        </li>
      </ul>
    </div>
    <div class="built-by">
      Site by <a href="https://kismet-solutions.com">Kismet Solutions</a>
    </div>
  </div>
</footer>
