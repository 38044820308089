<article class="privacy">
  <section class="privacy_content">
    <!-- /.privacy privacy_content -->
    <!-- /.privacy -->
    <h1 class="text-center">Pixie Gardens Privacy Policy</h1>

    <p class="privacy-info">Last Updated: January 15, 2021</p>
    <p>
      Thank you for visiting the website(s) (“Site”), products, services and
      applications (“Services”) of Pixie Gardens and for connecting with us
      online. We are committed to safeguarding and respecting your privacy and
      information. Contact us at
      <a href="mailto:office@pixiegardens.ca">office@pixiegardens.ca</a> if you
      have any questions or problems regarding the use of your personal data and
      we will be glad to assist you.
    </p>

    <p>
      Pixie Gardens and any affiliates are herein called Pixie Gardens. Your
      privacy and security are important to us; we know that you care about how
      your personal information is used and shared and we appreciate your trust
      that we will do so carefully and sensibly. By visiting our Website,
      conducting a transaction with our organization, engaging us for a project,
      signing up to receive information from us and/or other similar behaviour,
      you are consenting to the practices described in this Privacy Policy.
      Please take a moment to familiarize yourself with our Privacy Policy and
      contact us if you have any questions. This Privacy Policy sets out the
      privacy practices and policies for Pixie Gardens and the Website with
      respect to the collection, use and disclosure of personal information in
      connection with Pixie Gardens.
    </p>

    <h2>INFORMATION COLLECTION AND USE</h2>
    <p>
      While using our Site and/or doing business with us, we may ask you to
      provide us with certain personally identifiable information that can be
      used to contact or identify you. Personally identifiable information may
      include, but is not limited to your name, business name, property
      location, email, address, testimonies, project descriptions, project
      images and phone number(s).
    </p>
    <p>
      We use your Personal Information for various purposes in connection with
      our relationship with you for the provision of our products and services,
      including but not limited to: (a) to contact and correspond with you
      regarding your Gardening Service and transactions and to provide you with
      Customer services; (b) to provide you with information regarding our
      announcements, promotions, upcoming events, new products or services, and
      special features; (c) to facilitate transactions and process orders and
      possible payments for products and services purchased by you; (d) to
      process and respond to your specific suggestions, comments, inquires and
      requests, and to otherwise administer Pixie Gardens’ relationship with
      you; (e) to administer and facilitate your participation in potential
      sweepstakes, contests, and promotions; (f) to develop, deliver, and
      improve Pixie Gardens’ products, Site, services and advertising (g) to
      aggregate and otherwise assess customer data including consumer trends in
      behaviour; and (h) provide gifts or messages of gratitude for our
      relationship and/or business with you.
    </p>

    <h2>Log Data</h2>
    <p>
      Like many site operators, we collect information that your website browser
      sends whenever you visit our Site (referred to as “Log Data”). This Log
      Data may include information such as your computer’s Internet Protocol
      (“IP”) address, browser type, browser version, the pages of our Site that
      you visit, the time and date of your visit, the time spent on those pages
      and other statistics.
    </p>

    <p>
      In addition, we may use third party services such as Google Analytics that
      collect, monitor and analyze the basic demographics of our Site audience
      such as city, province and country, time of visit, etc. The data we
      collect via website analytics is primarily used to improve the operation
      of our website and is typically assessed based on aggregate data, not
      individually assessed data.
    </p>
    <h2>Communications</h2>

    <p>
      We may use your Personal Information to contact you with blog updates,
      newsletters, marketing or promotional materials and other information that
      we deem helpful in providing services related to our website. We would
      contact you in a manner according to Canada’s Anti-Spam Legislation
      (CASL). You may easily opt-out of receiving promotional e-mails in
      accordance with this Privacy Policy.
    </p>
    <h2>Cookies</h2>

    <p>
      Cookies are files with a small amount of data, which may include an
      anonymous unique identifier. Cookies are sent to your browser from a
      website and stored on your computer’s hard drive. Like many sites, we may
      use “cookies” to collect information. You can instruct your browser to
      refuse all cookies or to indicate when a cookie is being sent. However, if
      you do not accept cookies, you may not be able to use some portions of our
      Site.
    </p>

    <p>
      Pixie Gardens’ website, online services, e-mail messages, and
      advertisements may use “cookies” and other similar technologies, such as
      web beacons, pixels, gif tags, and action tags to confirm that e-mail
      messages have been delivered and opened or other digital actions have
      occurred. The cookie technologies collect information that assist Pixie
      Gardens to improve products and services, advertising, customer
      communications and the customer experience.
    </p>
    <p>
      For example, we may use information collected through cookies and similar
      technologies to recognize the user of our website and record the usage
      behaviour of the website, offer personalized web page content, information
      and advertisements, and otherwise facilitate and improve the website
      experience. You may choose to decline or disable cookies if your web
      browser permits, but doing so may affect your ability to access or use
      certain features of Pixie Gardens’ website.
    </p>

    <p>
      Information provided by cookies may be collected and processed by Pixie
      Gardens or by its third party service providers, including but not limited
      to Google, Facebook, Twitter, Instagram, LinkedIn and others.
    </p>
    <h2>Third Party Analytics of Ad Serving Services</h2>
    <p>
      Pixie Gardens may from time to time, use certain third-party analytics
      and/or ad serving services on its websites. The providers of these
      services may independently collect, use, and disclose your IP address
      together with information (not including your name, address, e-mail
      address, or telephone number) about your visits to this and other websites
      that they service in accordance with their own privacy policies (and not
      this privacy policy). These services may use their own cookies and other
      data collection technologies to gather and analyze data on consumer
      behaviour. They may use this information in order to improve your
      experience, to gain a view of how people are using websites that they
      service so that improvements can be made, and as otherwise permitted in
      their privacy policies.
    </p>
    <h2>INTERNET-BASED ADVERTISING</h2>
    <p>
      Pixie Gardens’ website may use cookies, web beacons and other tracking
      technologies to store on your browser information about your use of our
      website (including pages viewed, tracking conversions, and the Internet
      (IP) address of your computer) so that our third party service providers
      (such as Facebook) may display advertisements likely to be of interest to
      you when you access other websites, social media sites or other online
      services.
    </p>
    <p>
      You may opt-out of receiving interest-based advertisements on a website,
      social media site or other online service by using their advertising
      preferences settings. If you do so, you may still see Pixie Gardens’ ads
      on the site but the ads will no longer be based on tracking technologies.
    </p>
    <h2>RETENTION, LOCATION, AND PROTECTION OF PERSONAL INFORMATION</h2>
    <p>
      Pixie Gardens retains your personal information for the period necessary
      to fulfill the purposes outlined in this Privacy Policy or such longer
      retention period as may be required or permitted by applicable law.
    </p>
    <p>
      Pixie Gardens will store and process your personal information that it
      holds directly, in Canada. Third parties such as but not limited to Google
      Analytics and Facebook may store personal information in other countries,
      according to their policies. The laws of other countries regarding the
      collection, use, disclosure, and retention of personal information may be
      different from the laws of Canada, and their laws may be different from
      the laws of the country where you reside. The disclosure of your
      information in accordance with this Privacy Policy may result in your
      personal information being transferred outside of Canada, the United
      States, or your country.
    </p>
    <p>
      Pixie Gardens employs reasonable safeguards – including administrative,
      technical and physical measures – appropriate to the sensitivity of the
      personal information within its possession or under its control in order
      to protect that information from unauthorized access, collection, use,
      disclosure, disposal, or similar risks. While Pixie Gardens uses
      reasonable efforts to protect your information, no method of transmission
      in person, over the Internet, or method of electronic or physical storage,
      is 100% secure, and therefore, Pixie Gardens cannot guarantee absolute
      security of your personal information.
    </p>
    <h2>SECURITY</h2>
    <p>
      The security of your Personal Information is important to us, but remember
      that no method of transmission in person, over the Internet, or method of
      electronic or physical storage, is 100% secure. While we strive to use
      commercially acceptable means to protect your Personal Information, we
      cannot guarantee its absolute security.
    </p>
    <h2>CHANGES TO THIS PRIVACY POLICY</h2>
    <p>
      This Privacy Policy is effective as of March 15, 2020 and will remain in
      effect except with respect to any changes in its provisions in the future,
      which will be in effect immediately after being posted on this page. We
      reserve the right to update or change our Privacy Policy at any time and
      you should check this Privacy Policy periodically. Your continued use of
      the Service after we post any modifications to the Privacy Policy on this
      page will constitute your acknowledgement of the modifications and your
      consent to abide and be bound by the modified Privacy Policy. If we make
      material changes to this Privacy Policy, we will notify you either through
      the email address you have provided us, or by placing a notice on our
      website.
    </p>
    <p>Questions about the Privacy Policy should be sent to us at:</p>
    <p class="privacy_address">
      Pixie Gardens <br />
      Attention:
      Privacy Officer<br />
      Address: Box 15061 Aspen Woods RPO Calgary AB T3H 0N8<br />
      Phone: 403-228-2802<br />
      Email: Office@pixiegardens.ca
    </p>
  </section>
</article>
