import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {RouterModule, Routes} from "@angular/router";

import { AppComponent } from './app.component';
import { HomeComponent } from './Pages/home/home.component';
import { PrivacyComponent } from './Pages/privacy/privacy.component';
import { NavbarComponent } from './Shared/navbar/navbar.component';
import { FooterComponent } from './Shared/footer/footer.component';
import { TermsComponent } from './Pages/terms/terms.component';
import { ServicesComponent } from './Pages/services/services.component';
import { MeetusComponent } from './Pages/meetus/meetus.component';
import { ContactusComponent } from './Pages/contactus/contactus.component';


const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'services', component: ServicesComponent},
  {path: 'meet-us', component: MeetusComponent},
  {path: 'contact-us', component: ContactusComponent},
  {path: 'privacy-policy', component: PrivacyComponent},
  {path: 'terms-and-conditions', component: TermsComponent},
]


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PrivacyComponent,
    NavbarComponent,
    FooterComponent,
    TermsComponent,
    ServicesComponent,
    MeetusComponent,
    ContactusComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
