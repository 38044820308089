<article>
  <section class="main">
    <div class="logo">
      <img src="/assets/icons/PixieGardens.RGB_.png" alt="Pixie Gardens logo" />
    </div>
  </section>

  <section class="services">
    <div class="maintenance">
      <img
        src="/assets/Images/Garden.png"
        alt="beautiful garden along walkway"
      />
      <h3 class="text-center">Garden Maintenance</h3>
      <ul class="service_list">
        <li><p>Maintain</p></li>
        <li><p>|</p></li>
        <li><p>Evolve</p></li>
        <li><p>|</p></li>
        <li><p>Enhance</p></li>
      </ul>
      <p>Low maintenance, front yard rain garden</p>
    </div>
    <div class="seasonal">
      <img src="/assets/Images/container.jpg" alt="" />
      <h3 class="text-center">Seasonal Containers</h3>
      <ul class="service_list">
        <li><p>Design</p></li>
        <li><p>|</p></li>
        <li><p>Install</p></li>
        <li><p>|</p></li>
        <li><p>Maintain</p></li>
      </ul>
      <p>For all seasons and outdoor gatherings</p>
    </div>
    <div class="rain-wise">
      <img src="/assets/Images/rainwater-harvesting-square-2.png" alt="" />
      <h3 class="text-center">Rain Wise Gardens</h3>
      <ul class="service_list">
        <li><p>Assess</p></li>
        <li><p>|</p></li>
        <li><p>Design</p></li>
        <li><p>|</p></li>
        <li><p>Install</p></li>
      </ul>
      <p>Detailed service for residential flower beds</p>
    </div>
  </section>
  <section class="area">
    <div class="gloves_lost cards">
      <div class="picture">
        <img src="/assets/Images/jonathan-kemper-CbZh3kaPxrE-unsplash.jpg" alt="">
      </div>
      <!-- /.picture -->
      <div class="info">
        <!-- /.info -->
        <ul>
          <li>
            <h2>45000+</h2>
            <p>annuals planted per year</p>
          </li>
          <li>
            <h2>2003</h2>
            <p>year Pixie Gardens started</p>
          </li>
          <li>
            <h2>52</h2>
            <p>gardening gloves lost</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="service_area cards">
      <div class="info">
        <h3>Service areas: West Calgary, west of Calgary, Inner city</h3>
        <p class="muted">No other areas are available at this time</p>
      </div>
      <div class="picture">
        <img
          src="/assets/Images/sandie-clarke-q13Zq1Jufks-unsplash.jpg"
          alt="garden planting"
        />
      </div>
      <!-- /.picture -->
      <!-- /.info -->
    </div>

  </section>
<section class="form">

</section>
  <section class="newsletter">

  </section>
  <section class="testimonial">

  </section>

</article>
