<nav>
  <div class="navbar">
    <ul>
      <li>
        <a routerLink="" routerLinkActive="active-link">Home</a>
      </li>
      <li>
        <a routerLink="/services" routerLinkActive="active-link"
          >Gardening Services</a
        >
      </li>
      <li>
        <a routerLink="/meet-us" routerLinkActive="active-link"
          >Meet the Pixies</a
        >
      </li>
      <li>
        <a routerLink="/contact-us" routerLinkActive="active-link">Contact</a>
      </li>
    </ul>
  </div>
</nav>
